
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as _91slug_93N02SV6wXy2Meta } from "/vercel/path0/pages/article/[slug].vue?macro=true";
import { default as _91slug_93Po03rnzIFtMeta } from "/vercel/path0/pages/blog/[slug].vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as _91slug_93cItlWoLqLmMeta } from "/vercel/path0/pages/collection/[slug].vue?macro=true";
import { default as indexKWzcwof1WgMeta } from "/vercel/path0/pages/collections/index.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as indexIavKwQ6Un0Meta } from "/vercel/path0/pages/explore/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexQZTg8uc0JnMeta } from "/vercel/path0/pages/join/index.vue?macro=true";
import { default as successLL7SCVuuH6Meta } from "/vercel/path0/pages/join/success.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as _91slug_93eChLEfX15sMeta } from "/vercel/path0/pages/perform/rudiment/[slug].vue?macro=true";
import { default as indexeWSnCicTy9Meta } from "/vercel/path0/pages/play/account/index.vue?macro=true";
import { default as subscription7sl2EuRYRDMeta } from "/vercel/path0/pages/play/account/subscription.vue?macro=true";
import { default as _91slug_93GCWlTxxYIPMeta } from "/vercel/path0/pages/play/collection/[slug].vue?macro=true";
import { default as indexdmIN9NUIgnMeta } from "/vercel/path0/pages/play/collections/index.vue?macro=true";
import { default as indexu2C4l1JKCMMeta } from "/vercel/path0/pages/play/explore/index.vue?macro=true";
import { default as favoriteseZCCQ3bG2yMeta } from "/vercel/path0/pages/play/favorites.vue?macro=true";
import { default as index7Pae3TRU1AMeta } from "/vercel/path0/pages/play/index.vue?macro=true";
import { default as notifications7SX2jyWCNaMeta } from "/vercel/path0/pages/play/notifications.vue?macro=true";
import { default as _91slug_93H1Wzwvnf2uMeta } from "/vercel/path0/pages/play/rudiment/[slug].vue?macro=true";
import { default as _91slug_93ZhbxVB32ioMeta } from "/vercel/path0/pages/policy/[slug].vue?macro=true";
import { default as _91slug_93MTnaY2Oh9tMeta } from "/vercel/path0/pages/rudiment/[slug].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "article-slug",
    path: "/article/:slug()",
    component: () => import("/vercel/path0/pages/article/[slug].vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/vercel/path0/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/vercel/path0/pages/blog/index.vue")
  },
  {
    name: "collection-slug",
    path: "/collection/:slug()",
    component: () => import("/vercel/path0/pages/collection/[slug].vue")
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/vercel/path0/pages/collections/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("/vercel/path0/pages/explore/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/vercel/path0/pages/join/index.vue")
  },
  {
    name: "join-success",
    path: "/join/success",
    component: () => import("/vercel/path0/pages/join/success.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "perform-rudiment-slug",
    path: "/perform/rudiment/:slug()",
    meta: _91slug_93eChLEfX15sMeta || {},
    component: () => import("/vercel/path0/pages/perform/rudiment/[slug].vue")
  },
  {
    name: "play-account",
    path: "/play/account",
    meta: indexeWSnCicTy9Meta || {},
    component: () => import("/vercel/path0/pages/play/account/index.vue")
  },
  {
    name: "play-account-subscription",
    path: "/play/account/subscription",
    meta: subscription7sl2EuRYRDMeta || {},
    component: () => import("/vercel/path0/pages/play/account/subscription.vue")
  },
  {
    name: "play-collection-slug",
    path: "/play/collection/:slug()",
    meta: _91slug_93GCWlTxxYIPMeta || {},
    component: () => import("/vercel/path0/pages/play/collection/[slug].vue")
  },
  {
    name: "play-collections",
    path: "/play/collections",
    meta: indexdmIN9NUIgnMeta || {},
    component: () => import("/vercel/path0/pages/play/collections/index.vue")
  },
  {
    name: "play-explore",
    path: "/play/explore",
    meta: indexu2C4l1JKCMMeta || {},
    component: () => import("/vercel/path0/pages/play/explore/index.vue")
  },
  {
    name: "play-favorites",
    path: "/play/favorites",
    meta: favoriteseZCCQ3bG2yMeta || {},
    component: () => import("/vercel/path0/pages/play/favorites.vue")
  },
  {
    name: "play",
    path: "/play",
    meta: index7Pae3TRU1AMeta || {},
    component: () => import("/vercel/path0/pages/play/index.vue")
  },
  {
    name: "play-notifications",
    path: "/play/notifications",
    meta: notifications7SX2jyWCNaMeta || {},
    component: () => import("/vercel/path0/pages/play/notifications.vue")
  },
  {
    name: "play-rudiment-slug",
    path: "/play/rudiment/:slug()",
    meta: _91slug_93H1Wzwvnf2uMeta || {},
    component: () => import("/vercel/path0/pages/play/rudiment/[slug].vue")
  },
  {
    name: "policy-slug",
    path: "/policy/:slug()",
    component: () => import("/vercel/path0/pages/policy/[slug].vue")
  },
  {
    name: "rudiment-slug",
    path: "/rudiment/:slug()",
    component: () => import("/vercel/path0/pages/rudiment/[slug].vue")
  }
]